import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import { FeatureToggle } from './featureHook'

export interface Secrets {
  appInsightsSecretKey: string | null
}

export interface DynamicConfig {
  environment: 'unknown' | 'local' | 'development' | 'prod'
  apiUrl: string | null
  googleClientId: string | null
  stripePublicKey: string | null
  secrets: Secrets
  features: FeatureToggle[] | null
}

interface ConfigContextType {
  config: DynamicConfig | null
  loading: boolean
  error: boolean
}

const ConfigContext = createContext<ConfigContextType>({
  config: null,
  loading: true,
  error: false,
})

export const useConfig = () => useContext(ConfigContext)

export const ConfigProvider: React.FC<{ children: React.ReactNode, fallback: React.ReactNode }> = ({ children, fallback }) => {
  const [ config, setConfig ] = useState<DynamicConfig | null>(null)
  const [ loading, setLoading ] = useState(true)
  const [ error, setError ] = useState(false)

  useEffect(() => {
    const configFileName = process.env.NODE_ENV === 'development'
      ? '/env/config.local.json'
      : '/config.json'

    const loadConfig = async () => {
      try {
        const { data: loadedConfig } = await axios.get(configFileName)
        const baseConfig: DynamicConfig = {
          ...loadedConfig,
          secrets: { appInsightsSecretKey: null },
          features: null,
        }
        setConfig(baseConfig)

        if (!loadedConfig.apiUrl)
          console.error('API URL is not valid')

        const { data: secrets } = await axios.get(`${ loadedConfig.apiUrl }configuration/secrets`)

        setConfig({
          ...baseConfig,
          secrets: { appInsightsSecretKey: secrets.appInsightsSecretKey },
        })

        const { data: toggles } = await axios.get(`${ loadedConfig.apiUrl }feature/all`)
        setConfig((prevConfig) => {
          if (!prevConfig)
            return prevConfig
          return { ...prevConfig, features: toggles }
        })
      } catch (err) {
        console.error('Error fetching configuration, secrets, or features:', err)
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    loadConfig()
  }, [])

  if (loading) return <div>{ fallback }</div>

  return (
    <ConfigContext.Provider value={ { config, loading, error } }>
      { children }
    </ConfigContext.Provider>
  )
}