import React from 'react'
import { useAuth } from './authContext'
import { Navigate, useLocation } from 'react-router-dom'
import SplashScreen from '../pages/SplashScreen'
import { PageTransition } from '../components/PageTransition'

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth()
  const location = useLocation()

  if (isLoading) {
    return <PageTransition> <SplashScreen /> </PageTransition>
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" state={ { from: location } } replace />
  }

  return <>{ children }</>
}

export const ProtectedAdminRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isAuthenticated, isLoading } = useAuth()
  const location = useLocation()

  if (isLoading) {
    return <PageTransition> <SplashScreen /> </PageTransition>
  }

  if (!isAuthenticated) {
    return <Navigate to="/auth" state={ { from: location } } replace />
  }

  if (!user?.isAdmin) {
    return <Navigate to="/home" state={ { from: location } } replace />
  }
  
  return <>{ children }</>
}

