import React from 'react'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import useApi from './../utils/api'
import { useConfig } from '../Infrastructure/hooks/ConfigProvider'
import { useAuth } from './authContext'
import { useNavigate } from 'react-router-dom'

const AuthProviders: React.FC = () => {
  return (
    <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' } }>
      <GoogleAuth />
    </div>
  )
}

const GoogleAuth = () => {
  const api = useApi()
  const navigate = useNavigate()

  const { signIn } = useAuth()
  const { config } = useConfig()

  const handleSigninSuccess = async (response: any) => {
    try {
      const res = await api.post<{ token: string }>(`referential/google-response`, { token: response.credential })
      
      if (res.data?.token)
        signIn(res.data?.token)
      
      navigate('/home')
    } catch (error) {
      console.error('Authentication Error:', error)
    }
  }

  const handleSigninFailure = () => {
    console.error('Login Failed')
  }

  return (
    <GoogleOAuthProvider clientId={ config?.googleClientId ?? "" }>
      <GoogleLogin
        type={ 'icon' }
        shape={ 'circle' }
        size={ 'large' }
        onSuccess={ handleSigninSuccess }
        onError={ handleSigninFailure }
      />
    </GoogleOAuthProvider>
  )
}

export default AuthProviders
