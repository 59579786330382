import React, { useEffect, useState } from 'react';
import { Box, Typography, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useApi from '../utils/api';

interface FeatureToggle {
  id: string
  name: string
  description: string
  isEnabled: boolean
}

interface AdminFeatureToggleTabProps {
  setSnackbar: (state: SnackbarState) => void;
  handleApiCall: <T>(apiCall: Promise<{ data: T | null; status: number; message: string }>) => Promise<T | null>
}

export default function AdminFeatureToggleTab({ handleApiCall }: AdminFeatureToggleTabProps): JSX.Element {
  const api = useApi();
  const { t } = useTranslation();

  const [ featureToggles, setFeatureToggles ] = useState<FeatureToggle[]>([]);

  useEffect(() => {
    fetchFeatureToggles()
  }, []);

  const fetchFeatureToggles = async () => {
    let featureToggles = await api.get<FeatureToggle[]>('/feature/all')
    setFeatureToggles(featureToggles.data ?? [])
  }

  const handleToggleChange = async (id: string, isEnabled: boolean) => {
    setFeatureToggles((prev) =>
      prev.map((toggle) =>
        toggle.id === id
          ? { ...toggle, isEnabled }
          : toggle)
    );
    await handleApiCall(
      api.patch(`/feature/${ id }/toggle/${ isEnabled }`, null));
  };

  return (
    <Box sx={ { mt: 4 } }>
      <Typography variant="h2" sx={ { marginBottom: '1em', fontWeight: 'bold' } }>
        { t('pages.admin.manageFeatureToggles') }
      </Typography>
      {
        featureToggles.map((toggle) => (
          <div style={ { marginBottom: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } }>
            <Box flexDirection={ 'column' }>
              <Typography variant="body1" sx={ { fontWeight: 'bold', marginLeft: 1 } }>
                { toggle.name }
              </Typography>
              <Typography variant="body2" sx={ { mb: 1, marginLeft: 1 } }>
                ({ toggle.description })
              </Typography>
            </Box>
            <Switch
              sx={ { marginRight: 2 } }
              checked={ toggle.isEnabled }
              onChange={ () => handleToggleChange(toggle.id, !toggle.isEnabled) }
              color="primary"
            />
          </div>
        ))
      }
    </Box>
  );
}