import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, CardMedia, Typography, Button, Collapse } from '@mui/material';
import { Tutorial } from '../models/dtos/tutorial';
import TutorialContentRateScore from './TutorialContentRateScore';
import { useTranslation } from 'react-i18next';
import { useFeature } from '../Infrastructure/hooks/featureHook'

type TutorialCardProps = {
  tutorial: Tutorial;
  isFirstInList: boolean;
  isLastInList: boolean;
  onAddCart: (tutorial: Tutorial) => void;
};

export default function TutorialCard({ tutorial, onAddCart, isFirstInList, isLastInList }: TutorialCardProps) {
  const { t } = useTranslation();
  const { hasFeature } = useFeature()

  const [ imageSrc, setImageSrc ] = useState<string>(tutorial.bannerImageUrl ?? '');
  const [ expanded, setExpanded ] = useState(false);

  const handleError = () => {
    setImageSrc('https://www.r2iimmobilier.fr/wp-content/uploads/2022/09/5015_461_Deficit-foncier-2-830x350.jpg');
  };

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <Card
      sx={ {
        marginLeft: isFirstInList ? '1rem' : '0',
        marginRight: isLastInList ? '1rem' : '0',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        width: '20em',
        borderRadius: '8px',
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:hover': { transform: 'scale(1.02)' },
      } }>
      <CardMedia
        component="img"
        image={ imageSrc }
        alt={ tutorial.title }
        onError={ handleError }
        sx={ {
          height: 140,
          objectFit: 'cover',
          borderBottom: '1px solid #ddd',
        } } />

      <CardContent sx={ { padding: 1 } }>
        <Typography
          variant="h6"
          sx={ {
            fontWeight: 'bold',
            marginBottom: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          } }>
          { tutorial.title }
        </Typography>

        <Collapse
          in={ expanded }
          timeout="auto"
          collapsedSize="3.5em"
          sx={ {
            overflow: 'hidden',
            transition: 'height 0.3s ease',
          } }>
          <Typography
            variant="body2"
            sx={ {
              wordBreak: 'break-word',
              lineHeight: '1.5em',
            } }>
            { tutorial.description }
          </Typography>
        </Collapse>

        <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } }>
          <Button
            size="small"
            sx={ { textTransform: 'none', color: 'primary.main', paddingLeft: 0, marginTop: 1 } }
            onClick={ toggleExpanded }>
            { expanded ? t('general.viewLess') : t('general.viewMore') }
          </Button>

          { hasFeature('DisplayTutorialRate') && tutorial.rateInPercent !== null && tutorial.rateCounts !== null && (
            <Box sx={ { display: 'flex', justifyContent: 'flex-end', marginTop: 1 } }>
              <TutorialContentRateScore rateInPercent={ tutorial.rateInPercent } rateCounts={ tutorial.rateCounts } />
            </Box>
          ) }
        </div>
      </CardContent>

      <CardActions sx={ { padding: 1, justifyContent: 'space-between', borderTop: '1px solid #ddd' } }>
        <Typography variant="h6" color="primary" sx={ { fontWeight: 'bold' } }>
          { tutorial.price } €
        </Typography>
        <Button
          size="small"
          sx={ {
            textTransform: 'none',
            color: 'primary.main',
            fontWeight: 'bold',
          } }
          onClick={ () => onAddCart(tutorial) }>
          { t('general.addToCart') }
        </Button>
      </CardActions>
    </Card>
  );
}
