import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useConfig } from './hooks/ConfigProvider'
import SplashScreen from '../pages/SplashScreen'
import Fallback from '../pages/Fallback'
import Home from '../pages/Home'
import { AnimatePresence } from 'framer-motion'
import { PageTransition } from '../components/PageTransition'
import { ProtectedAdminRoute, ProtectedRoute } from '../auth/ProtectedRoute'
import AuthenticationPage from '../auth/AuthenticationPage'
import CartPage from '../pages/Cart'
import AdminPage from '../pages/Admin'

const AppRouter: React.FC = () => {
  const location = useLocation()

  const { loading, error } = useConfig()

  if (loading)
    return <PageTransition children={ <SplashScreen /> } />

  if (error)
    return <PageTransition children={ <Fallback /> } />

  return (
    <AnimatePresence mode={ 'wait' }>
      <Routes location={ location } key={ location.pathname }>
        <Route
          path="*" element={
          <ProtectedRoute>
            <PageTransition>
              <Home />
            </PageTransition>
          </ProtectedRoute> } />

        <Route
          path="/" element={
          <ProtectedRoute>
            <PageTransition>
              <Home />
            </PageTransition>
          </ProtectedRoute> } />

        <Route
          path="/auth" element={
          <PageTransition>
            <AuthenticationPage />
          </PageTransition> } />

        <Route
          path="/splash" element={
          <PageTransition>
            <SplashScreen />
          </PageTransition> } />

        <Route
          path="/fallback" element={
          <PageTransition>
            <Fallback />
          </PageTransition> } />

        <Route
          path="/home" element={
          <ProtectedRoute>
            <PageTransition>
              <Home />
            </PageTransition>
          </ProtectedRoute> } />

        <Route
          path="/cart" element={
          <ProtectedRoute>
            <PageTransition>
              <CartPage />
            </PageTransition>
          </ProtectedRoute> } />

        <Route
          path="/admin" element={
          <ProtectedAdminRoute>
            <PageTransition>
              <AdminPage />
            </PageTransition>
          </ProtectedAdminRoute> } />

      </Routes>
    </AnimatePresence>
  )
}


export default AppRouter