import { useConfig } from './ConfigProvider'

export interface FeatureToggle {
  id: string
  name: string
  code: string
  isEnabled: boolean
}

export const useFeature = () => {
  const { config } = useConfig()

  const hasFeature = (code: string): boolean => {
    if (!config?.features) return false

    const feature = config.features.find((f) => f.code === code)

    return feature?.isEnabled ?? false
  }

  return { hasFeature }
}