import React from 'react';
import { Box, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import { useThemeMode } from '../utils/theme/themeContext'

type TutorialRatingProps = {
  rateInPercent: number | null;
  rateCounts: number | null;
};

export default function TutorialContentRateScore({ rateInPercent, rateCounts }: TutorialRatingProps) {
  const theme = useThemeMode()
  
  return (
    <Box sx={ { display: 'flex', alignItems: 'center', gap: 0.5 } }>
      <ThumbUpOutlinedIcon sx={ { color: theme.theme.palette.success.main, fontSize: '18px' } } />
      <Typography variant="body2" sx={ { fontWeight: 'bold', color: theme.theme.palette.success.main } }>
        { rateInPercent !== null ? `${ (rateInPercent * 100).toFixed(1) }%` : 'N/A' }
      </Typography>
      <Typography variant="body2" sx={{fontSize: '0.7rem'}}>
        { rateCounts !== null ? `(${ rateCounts } votes)` : '(0 votes)' }
      </Typography>
    </Box>
  );
}