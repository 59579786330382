import React, { createContext, useState, useContext, ReactNode } from 'react'
import { ThemeProvider, createTheme, useMediaQuery, Theme } from '@mui/material'
import { darkTheme, lightTheme } from './theme'
import { CssBaseline, GlobalStyles } from '@mui/material'

interface ThemeModeContextProps {
  theme: Theme
  darkMode: boolean
  isMdScreenOrLower: boolean
  isSmScreenOrLower: boolean
  isXxlScreenOrAbove: boolean
  toggleTheme: () => void
}

const ThemeContext = createContext<ThemeModeContextProps | undefined>(undefined)

export const useThemeMode = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useThemeMode must be used within a ThemeProvider')
  }
  return context
}

export const CustomThemeModeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [ darkMode, setDarkMode ] = useState<boolean>(false)
  const theme = createTheme(darkMode ? darkTheme : lightTheme)

  const isMdScreenOrLower = useMediaQuery(theme.breakpoints.down('md'))
  const isSmScreenOrLower = useMediaQuery(theme.breakpoints.down('sm'))
  const isXxlScreenOrAbove = useMediaQuery('(min-width:2500px)')
  
  const toggleTheme = () => {
    setDarkMode((prevModeIsDarkMode: boolean) => {
      const newMode = !prevModeIsDarkMode
      localStorage.setItem('darkMode', JSON.stringify(newMode))
      document.documentElement.setAttribute('data-color-mode', prevModeIsDarkMode ? 'light' : 'dark')
      return newMode
    })
  }

  return (
    <ThemeContext.Provider value={ { darkMode, isMdScreenOrLower, isSmScreenOrLower, isXxlScreenOrAbove, toggleTheme, theme } }>
      <ThemeProvider theme={ theme }>
        <CssBaseline />
        <GlobalStyles styles={ { '*': { transition: 'background-color 0.5s ease, color 0.5s ease', }, } } />
        { children }
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}
