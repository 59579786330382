import React from 'react'
import { Container, Typography, keyframes, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TopBarWithLayout from '../components/TopBarWithLayout'

const pulseAnimation = keyframes`
	0% {
		opacity: 0.3
	}
	50% {
		opacity: 1
	}
	100% {
		opacity: 0.3
	}
`

const SplashScreen: React.FC = () => {
  const { t } = useTranslation()

  return (
    <TopBarWithLayout>
      <Container
        maxWidth="xl"
        sx={ {
          marginTop: '10%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        } }
      >
        <Typography
          variant="h1"
          sx={ { textAlign: 'center', animation: `${ pulseAnimation } 1.5s infinite ease-in-out`, marginBottom: "1em"} }>
          { t('general.loading') }
        </Typography>

        <CircularProgress  size={80} />

      </Container>
    </TopBarWithLayout>
  )
}

export default SplashScreen
