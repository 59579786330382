import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, TextField, Select, MenuItem, Typography, InputAdornment, SelectChangeEvent } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import ImageCropUploader from '../components/ImageCropUploader';
import { useTranslation } from 'react-i18next';
import useApi from '../utils/api';
import { AdminFullTutorialDto } from '../models/dtos/adminFullTutorialDto';
import { FullCategory } from '../models/dtos/category';
import { Guid } from 'guid-typescript'
import { useThemeMode } from '../utils/theme/themeContext'
import '@uiw/react-md-editor/markdown-editor.css';
import '@uiw/react-markdown-preview/markdown.css';

const defaultTutorial: AdminFullTutorialDto = {
  id: null,
  code: '',
  title: '',
  description: '',
  language: 'fr',
  version: '2024',
  price: '',
  tags: '',
  content: '',
  categoryId: '',
  bannerUrl: '',
};

interface AdminTutorialTabProps {
  setSnackbar: (state: SnackbarState) => void;
  handleApiCall: <T>(apiCall: Promise<{ data: T | null; status: number; message: string }>) => Promise<T | null>
}

export default function AdminTutorialTab({ setSnackbar, handleApiCall }: AdminTutorialTabProps): JSX.Element {
  const api = useApi();
  const { t } = useTranslation();
  const [ tutorials, setTutorials ] = useState<AdminFullTutorialDto[]>([]);
  const [ categories, setCategories ] = useState<FullCategory[]>([]);
  const [ tutorial, setTutorial ] = useState<AdminFullTutorialDto>(defaultTutorial);
  const [ content, setContent ] = useState<string>('');
  const { isSmScreenOrLower } = useThemeMode()

  useEffect(() => {
    fetchTutorials();
    fetchCategories();
  }, []);

  const fetchTutorials = async () => {
    const result = await api.get<AdminFullTutorialDto[]>('/adminProduct/tutorials');
    setTutorials(result.data || []);
  };

  const fetchCategories = async () => {
    const result = await api.get<FullCategory[]>('/adminProduct/categories');
    setCategories(result.data || []);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setTutorial((prev) => ({ ...prev, [ name ]: value }));
  };

  const handleSaveTutorial = async () => {
    setSnackbar({ open: true, message: t('pages.admin.success'), severity: 'success' });
    await fetchTutorials();
  };

  const handleSelectTutorial = (id: Guid) => {
    const selected = tutorials.find((t) => t.id === id) || defaultTutorial;
    setTutorial(selected);
    setContent(selected.content);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    if (name) {
      setTutorial((prev: AdminFullTutorialDto) => ({ ...prev, [ name ]: value as string }));
    }
  };

  async function handleOnImageUpload(file: any) {
    if (!!tutorial.id) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tutorialId', tutorial.id.toString());

      await handleApiCall(api.postFormData('/adminProduct/tutorial/banner', formData))
      await fetchTutorials()
    }
  }

  return (
    <Box sx={ { mt: 4 } }>
      <Typography variant="h2" sx={ { marginBottom: '1em', fontWeight: 'bold' } }>
        { t('pages.admin.manageTutorials') }
      </Typography>

      <Box display="grid" gridTemplateColumns={ { xs: '1fr', sm: '1fr', md: '1fr 1fr' } } gap={ isSmScreenOrLower ? 1 : 2 }>
        <Select
          value={ tutorial.id || 'new' }
          name="id"
          onChange={ (e) => handleSelectTutorial(e.target.value as Guid) }
          fullWidth>
          <MenuItem value="new">{ t('pages.admin.newTutorial') }</MenuItem>
          { tutorials.map((t) => (
            <MenuItem key={ t.id?.toString() } value={ t.id?.toString() }>
              { t.title }
            </MenuItem>
          )) }
        </Select>

        <Box display="grid" gridTemplateColumns={ { xs: '1fr 1fr', md: 'repeat(4, 1fr)' } } gap={ isSmScreenOrLower ? 1 : 2 }>
          <TextField
            label={ t('general.code') }
            name="code"
            value={ tutorial.code }
            onChange={ handleInputChange }
            fullWidth />
          <Select
            value={ tutorial.language }
            name="language"
            onChange={ handleSelectChange }
            fullWidth>
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="en">EN</MenuItem>
          </Select>

          <TextField
            label={ t('general.price') }
            name="price"
            value={ tutorial.price }
            slotProps={ { input: { endAdornment: <InputAdornment disableTypography position="end">€</InputAdornment> } } }
            onChange={ (e) => {
              const value = e.target.value;
              if (/^\d*\.?\d*$/.test(value)) handleInputChange(e);
            } }
            fullWidth />
          <Select
            value={ tutorial.version }
            name="version"
            onChange={ handleSelectChange }
            fullWidth>
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2024">2024</MenuItem>
            <MenuItem value="2025">2025</MenuItem>
          </Select>
        </Box>
      </Box>

      <Box display="grid" gridTemplateColumns={ { xs: '1fr', md: '3fr 1fr' } } gap={ isSmScreenOrLower ? 0 : 2 }>
        <TextField
          label={ t('general.title') }
          name="title"
          value={ tutorial.title }
          onChange={ handleInputChange }
          fullWidth />

        <Select
          value={ tutorial.categoryId }
          name="categoryId"
          onChange={ handleSelectChange }
          fullWidth>
          { categories.map((x) => (
            <MenuItem key={ x.id.toString() } value={ x.id.toString() }>
              { `${ x.code } - ${ x.name }` }
            </MenuItem>
          )) }
        </Select>
      </Box>

      <Box sx={ { display: 'flex', flexDirection: 'column' } } gap={ isSmScreenOrLower ? 0 : 2 }>
        <TextField label={ t('general.tags') } name="tags" value={ tutorial.tags } onChange={ handleInputChange } fullWidth multiline rows={ 2 } />
        <TextField label={ t('general.description') } name="description" value={ tutorial.description } onChange={ handleInputChange } fullWidth multiline rows={ 4 } />
        { !!tutorial.id &&
          <Box>
            <Typography variant="h4">{ t('general.content') }</Typography>
            <MDEditor style={ { marginTop: '1em' } } height={ 800 } value={ content } onChange={ (value) => setContent(value || '') } />
          </Box>
        }
        { !!tutorial.id &&
          <ImageCropUploader
            currentBannerUrl={ tutorial.bannerUrl }
            cropWidth={ 1920 }
            cropHeight={ 1080 }
            onImageUpload={ async (file: any) => await handleOnImageUpload(file) }
          />
        }
        <Button variant="contained" color="primary" onClick={ handleSaveTutorial } sx={ { mt: 2, mb: 2 } }>
          { tutorial.id ? t('general.update') : t('general.create') }
        </Button>
      </Box>
    </Box>
  )
}
