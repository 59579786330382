import React, { createContext, ReactNode, useContext, useState } from 'react'
import { CartItem } from '../models/cartItem'
import { Guid } from 'guid-typescript'

type CartContextType = {
  cart: CartItem[]
  addToCart: (item: CartItem) => void
  removeFromCart: (itemId: Guid) => void
  getItemsCount: () => number
}

type CartContextProviderProps = {
  children: ReactNode
}

const CartContext = createContext<CartContextType | undefined>(undefined)

export function CartContextProvider({ children }: CartContextProviderProps) {
  const [ cart, setCart ] = useState<CartItem[]>([])

  function addToCart(item: CartItem) {
    if (!cart.some(x => x.id === item.id))
      setCart([ ...cart, item ])
  }

  function removeFromCart(itemId: Guid) {
    const updatedCart = cart.filter((item) => item.id !== itemId)
    setCart(updatedCart)
  }

  function getItemsCount() {
    return cart.length
  }

  return (
    <CartContext.Provider value={ { cart, addToCart, removeFromCart, getItemsCount } }>
      { children }
    </CartContext.Provider>
  )
}

export const useCartContext = (): CartContextType => {
  const context = useContext(CartContext);

  if (!context) {
    console.warn('useCartContext must be used within a CartContextProvider');
    return {
      cart: [],
      addToCart: () => console.warn('CartContext is not available. addToCart called outside provider.'),
      removeFromCart: () => console.warn('CartContext is not available. removeFromCart called outside provider.'),
      getItemsCount: () => 0,
    };
  }

  return context;
};