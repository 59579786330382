import axios, { AxiosError, AxiosResponse } from 'axios'
import { useConfig, DynamicConfig } from '../Infrastructure/hooks/ConfigProvider'
import { useNavigate } from 'react-router-dom'

const createApi = (config: DynamicConfig, navigate: ReturnType<typeof useNavigate>) => {
  if (!config.apiUrl) throw new Error('No API URL found')
  const api = axios.create({ baseURL: config.apiUrl, timeout: 10000, })

  api.interceptors.request.use(
    (request) => {
      const token = localStorage.getItem('auth_token')
      if (token) request.headers[ 'Authorization' ] = `Bearer ${ token }`
      return request
    },
    (error) => Promise.reject(error)
  )

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => handleHttpErrors(error)
  )

  const buildApiEndpoint = (route: string): string => `${ config.apiUrl }${ route }`

  function handleHttpErrors(error: AxiosError) {
    console.error('HTTP request error', error?.response?.status, error.message)

    if (error.response) {
      if (error.response?.status === 401) {
        localStorage.removeItem('auth_token')
        navigate('/auth')
      }
      return { data: null, status: error?.response?.status ?? 500, message: error.message }
    } else if (error.request)
      return { status: 500, message: 'No response from server' }

    else
      return { status: 500, message: error.message }
  }

  const get = async <T>(endpoint: string, params?: any): Promise<{ data: T | null, status: number, message: string }> => {
    const response: AxiosResponse<T> = await api.get(endpoint, { params })
    return { data: response.data, status: response.status, message: 'Success' }
  }

  const post = async <T>(endpoint: string, data: any): Promise<{ data: T | null, status: number, message: string }> => {
    const response: AxiosResponse<T> = await api.post(endpoint, data)
    return { data: response.data, status: response.status, message: 'Success' }
  }

  const patch = async <T>(endpoint: string, data: any): Promise<{ data: T | null, status: number, message: string }> => {
    const response: AxiosResponse<T> = await api.patch(endpoint, data);
    return { data: response.data, status: response.status, message: 'Success' };
  };

  const postFormData = async <T>(endpoint: string, data: any): Promise<{ data: T | null, status: number, message: string }> => {
    const response: AxiosResponse<T> = await api.post(endpoint, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    return { data: response.data, status: response.status, message: 'Success' };
  }

  const put = async <T>(endpoint: string, data: any): Promise<{ data: T | null, status: number, message: string }> => {
    const response: AxiosResponse<T> = await api.put(endpoint, data)
    return { data: response.data, status: response.status, message: 'Success' }
  }

  return { get, post, postFormData, put, patch, buildApiEndpoint }
}

const useApi = () => {
  const { config } = useConfig()
  const navigate = useNavigate()

  if (!config?.apiUrl) {
    throw new Error('API URL is missing in the config')
  }

  return createApi(config, navigate)
}

export default useApi