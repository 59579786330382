import React, { useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import useApi from '../utils/api'
import TutorialCard from '../components/TutorialCard'
import { useCartContext } from '../shared/cartContext'
import { UserTutorial } from '../models/dtos/userTutorial'
import UserTutorialCard from '../components/UserTutorialCard'
import ProductContentDialog from '../components/ProductContentDialog'
import { useTranslation } from 'react-i18next'
import { Tutorial } from '../models/dtos/tutorial'
import { SearchBar } from '../components/SearchBar'
import TopBarWithLayout from '../components/TopBarWithLayout'

const Home: React.FC = () => {
  const api = useApi()
  const { addToCart } = useCartContext()
  const { t } = useTranslation()

  const [ tutorials, setTutorials ] = React.useState<Tutorial[]>([])
  const [ userTutorials, setUserTutorials ] = React.useState<UserTutorial[]>([])
  const [ openDialog, setOpenDialog ] = React.useState(false)
  const [ selectedTutorialContent, setSelectedTutorialContent ] = React.useState<string | null>(null)

  useEffect(() => {
    getAllTutorials()
    getUserTutorials()
  }, [])

  async function getAllTutorials() {
    const allTutorials = await api.get<Tutorial[]>('product/tutorial/all')
    setTutorials(allTutorials.data ?? [])
  }

  async function getUserTutorials() {
    const userTutorials = await api.get<UserTutorial[]>('product/tutorial/user/all')
    setUserTutorials(userTutorials.data ?? [])
  }

  function addTutorialToCart(tutorial: Tutorial) {
    addToCart({
      price: tutorial.price,
      name: tutorial.title,
      type: 'Tutorial',
      id: tutorial.id
    })
  }

  const handleOpenDialog = (content: string) => {
    setSelectedTutorialContent(content)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedTutorialContent(null)
  }

  return (
    <TopBarWithLayout showProfilePicture showCartButton>
      <Box sx={ { marginY: 2 } }>
        <Typography variant="h4" sx={ { marginLeft: '1rem', fontWeight: 900} }>
          { t('pages.home.allTutorials') }
        </Typography>

        <Box sx={ { marginLeft: '1rem', marginY: '1em' } }>
          <SearchBar
            onChange={ () => {
            } }
            onClear={ () => {
            } } />
        </Box>

        <Stack
          width="100%"
          overflow="-moz-initial"
          direction="row"
          spacing={ 2 }
          sx={ {
            paddingY: '0.5em',
            overflowX: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' }
          } }>
          { tutorials.map((x, i) => (
            <Box sx={ { width: '100%' } } key={ `tutorial-${ x.id.toString() }` }>
              <TutorialCard tutorial={ x } onAddCart={ addTutorialToCart } isFirstInList={ i === 0 } isLastInList={ i === tutorials.length - 1 } />
            </Box>
          )) }
        </Stack>
      </Box>

      <Box sx={ { marginY: 1.5 } }>
        <Typography variant="h4" sx={ { marginLeft: '1rem', fontWeight: 900} }>
          { t('pages.home.myTutorials') }
        </Typography>

        <Stack
          width="100%"
          overflow="-moz-initial"
          direction="row"
          spacing={ 2 }
          sx={ {
            paddingY: '0.5em',
            overflowX: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' }
          } }>
          { userTutorials.map((x, i) => (
            <Box sx={ { width: '100%' } } key={ `userTutorial-${ x.id.toString() }` }>
              <UserTutorialCard userTutorial={ x } onShowContent={ handleOpenDialog } isFirstInList={ i === 0 } isLastInList={ i === tutorials.length - 1 } />
            </Box>
          )) }
        </Stack>
      </Box>

      <ProductContentDialog
        openDialog={ openDialog }
        handleCloseDialog={ handleCloseDialog }
        selectedTutorialContent={ selectedTutorialContent ?? '' }
      />
    </TopBarWithLayout>
  )
}

export default Home
