import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useApi from '../utils/api';
import { FullCategory } from '../models/dtos/category';

interface AdminCategoryTabProps {
  setSnackbar: (state: SnackbarState) => void
  handleApiCall: <T>(apiCall: Promise<{ data: T | null; status: number; message: string }>) => Promise<T | null>
}

export default function AdminCategoryTab({ setSnackbar }: AdminCategoryTabProps): JSX.Element {
  const api = useApi();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<FullCategory[]>([]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const result = await api.get<FullCategory[]>('/adminProduct/categories');
    setCategories(result.data || []);
  };

  return (
    <Box sx={ { mt: 4 } }>
      <Typography variant="h2" sx={{ marginBottom: '1em', fontWeight: 'bold' }}>
        {t('pages.admin.manageCategories')}
      </Typography>
    </Box>
  );
}
