import React, { useState } from 'react'
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, CardProps, Collapse, IconButton, IconButtonProps, styled, Typography } from '@mui/material'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { UserTutorial } from '../models/dtos/userTutorial'
import useApi from '../utils/api'
import c from '@mui/icons-material/ThumbUpOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StarIcon from '@mui/icons-material/Star'
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import { useFeature } from '../Infrastructure/hooks/featureHook'
import TutorialContentRateScore from './TutorialContentRateScore'
import { useTranslation } from 'react-i18next'

type UserTutorialCardCardProps = {
  isFirstInList: boolean;
  isLastInList: boolean;
  userTutorial: UserTutorial
  onShowContent: (content: string) => void
} & CardProps

export default function UserTutorialCard({userTutorial, onShowContent, isFirstInList, isLastInList}: UserTutorialCardCardProps) {
  const api = useApi()
  const { t } = useTranslation();
  const { hasFeature } = useFeature()

  const [ imageSrc, setImageSrc ] = useState<string>(userTutorial.bannerImageUrl ?? '');
  const [ expanded, setExpanded ] = useState(false);

  async function getTutorialContent() {
    const content = await api.get<string>(`product/tutorial/${ userTutorial.id }/content`)
    if (content.data)
      onShowContent(content.data)
  }

  const toggleExpanded = () => setExpanded(!expanded);

  async function handleLike() {
    await api.post(`product/tutorial/${ userTutorial.id }/rateContent`, { rateValue: 1 });
  }

  async function handleDislike() {
    await api.post(`product/tutorial/${ userTutorial.id }/rateContent`, { rateValue: -1 });
  }

  const handleError = () => {
    setImageSrc('https://www.r2iimmobilier.fr/wp-content/uploads/2022/09/5015_461_Deficit-foncier-2-830x350.jpg');
  }

  return (
    <Card
      sx={ {
        marginLeft: isFirstInList ? '1rem' : '0',
        marginRight: isLastInList ? '1rem' : '0',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        width: '20em',
        borderRadius: '8px',
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:hover': { transform: 'scale(1.02)' },
      } }>
      <CardMedia
        component="img"
        image={ imageSrc }
        alt={ userTutorial.title }
        onError={ handleError }
        sx={ {
          height: 140,
          objectFit: 'cover',
          borderBottom: '1px solid #ddd',
        } } />

      <CardContent sx={ { padding: 1 } }>
        <Typography
          variant="h6"
          sx={ {
            fontWeight: 'bold',
            marginBottom: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          } }>
          { userTutorial.title }
        </Typography>

        <Collapse
          in={expanded}
          timeout="auto"
          collapsedSize="0"
          sx={{
            overflow: 'hidden',
            transition: 'height 0.3s ease',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              wordBreak: 'break-word',
              lineHeight: '1.5em',
            }}
          >
            {userTutorial.description}
          </Typography>
        </Collapse>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button
            size="small"
            sx={{ textTransform: 'none', color: 'primary.main', paddingLeft: 0, marginTop: 1 }}
            onClick={toggleExpanded}          >
            {expanded ? t('general.hideDescription') : t('general.showDescription')}
          </Button>
        </div>
      </CardContent>

      <CardActions sx={ { padding: 1, justifyContent: 'space-between', borderTop: '1px solid #ddd' } }>
        { hasFeature('RateTutorial') && (
          <Box sx={ { display: 'flex', gap: 1 } }>
            <IconButton onClick={ handleLike }>
              <ThumbUpOutlinedIcon />
            </IconButton>
            <IconButton onClick={ handleDislike }>
              <ThumbDownOffAltOutlinedIcon />
            </IconButton>
          </Box>
        ) } 
        <Button
          size="small"
          sx={ {
            textTransform: 'none',
            color: 'primary.main',
            fontWeight: 'bold',
          } }
          onClick={ getTutorialContent }>
          { t('general.showContent') }
        </Button>
      </CardActions>
    </Card>
    
    
    // <Card
    //   sx={ {
    //     marginLeft: props.isFirstInList ? '1rem' : '0',
    //     marginRight: props.isLastInList ? '1rem' : '0',
    //     boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     width: '22em',
    //     borderRadius: '8px',
    //     overflow: 'hidden',
    //     transition: 'transform 0.3s',
    //     '&:hover': { transform: 'scale(1.02)' }
    //   } }>
    //
    //   <CardMedia
    //     component="img"
    //     image={ imageSrc }
    //     alt={ props.userTutorial.title }
    //     onError={ handleError }
    //     sx={ {
    //       height: 180,
    //       objectFit: 'cover',
    //       borderBottom: '1px solid #ddd'
    //     } } />
    //
    //   <CardContent sx={ { padding: 1, paddingBottom: '0' } }>
    //     <Typography
    //       variant="h6"
    //       sx={ {
    //         fontWeight: 'bold',
    //         marginBottom: 1,
    //         overflow: 'hidden',
    //         whiteSpace: 'nowrap',
    //         textOverflow: 'ellipsis',
    //       } }>
    //       { props.userTutorial.title }
    //     </Typography>
    //
    //     <Typography
    //       variant="body2"
    //       color="text.primary"
    //       sx={ {
    //         height: '8em',
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //         display: '-webkit-box',
    //         WebkitBoxOrient: 'vertical',
    //         WebkitLineClamp: 4,
    //         wordBreak: 'break-word',
    //         lineHeight: '1.5em',
    //       } }>
    //       { props.userTutorial.title }
    //     </Typography>
    //
    //     {
    //       hasFeature('RateTutorial') &&
    //       <Box sx={ { display: 'flex', gap: 1 } }>
    //         <IconButton onClick={ handleLike }>
    //           <ThumbUpOutlinedIcon />
    //         </IconButton>
    //         <IconButton onClick={ handleDislike }>
    //           <ThumbDownOffAltOutlinedIcon />
    //         </IconButton>
    //       </Box>
    //     }
    //   </CardContent>
    //
    //   <CardActions disableSpacing>
    //     <IconButton aria-label="show" color={ 'inherit' } onClick={ getTutorialContent }>
    //       < VisibilityOutlinedIcon />
    //     </IconButton>
    //   </CardActions>
    // </Card>
  )
}