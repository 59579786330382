import { Box, Button, Container, Typography } from '@mui/material'
import React from 'react'
import { useCartContext } from '../shared/cartContext'
import useApi from '../utils/api'
import { useStripe } from '@stripe/react-stripe-js'
import { useAuth } from '../auth/authContext'
import { useTranslation } from 'react-i18next'
import TopBarWithLayout from '../components/TopBarWithLayout'

export default function CartPage() {
  const api = useApi()
  const { t } = useTranslation()
  const { user } = useAuth()
  const stripe = useStripe()
  const { cart } = useCartContext()

  function getTotalPrice(): number {
    if (!!cart.length)
      return cart.map(x => x.price).reduce((sum, acc) => sum + acc)
    return 0
  }

  const handleCheckout = async () => {
    let endpoint = api.buildApiEndpoint('payment/create-checkout-session')
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId: user?.id,
        tutorialIds: cart.map(x => x.id),
      }),
    })

    if (stripe) {
      const { sessionId } = await response.json()
      const { error } = await stripe.redirectToCheckout({ sessionId })

      if (error) {
        console.error("Error redirecting to checkout:", error)
      }
    }
  }

  return (
    <TopBarWithLayout>
      <div style={ { flexGrow: 1, marginTop: 4, marginBottom: 4 } }>
        <Container maxWidth="xl">
          <Container maxWidth="sm" sx={ { pt: 10, minHeight: 'calc(93vh - 5vh)' } }>
            <Box
              sx={ {
                p: 4,
                boxShadow: 3,
                borderRadius: 2,
                textAlign: 'center',
                backgroundColor: 'background.paper',
              } }>
              <Typography variant="h1" sx={ { fontSize: "4rem", mb: 8 } }>
                { t('pages.cart.title') }
              </Typography>

              { cart.map((x, index) => (
                <div key={ index } style={ { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' } }>
                  <Typography variant='subtitle2'>
                    { x.name }
                  </Typography>

                  <Typography variant='subtitle2'>
                    { x.price }€
                  </Typography>
                </div>
              )) }

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={ !getTotalPrice() }
                fullWidth
                sx={ { mt: 4, mb: 1 } }
                onClick={ handleCheckout }>
                { t('pages.cart.payButton') }
              </Button>

            </Box>
          </Container>
        </Container>
      </div>
    </TopBarWithLayout>
  )
}