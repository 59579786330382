import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Box, Button, Slider, Typography, TextField } from '@mui/material';
import imageCompression from 'browser-image-compression';
import { useTranslation } from 'react-i18next';

interface ImageCropUploaderProps {
  cropWidth: number,
  cropHeight: number,
  onImageUpload: (image: File | null) => void,
  currentBannerUrl?: string | null
}

export default function ImageCropUploader({ cropWidth, cropHeight, onImageUpload, currentBannerUrl }: ImageCropUploaderProps): JSX.Element {
  const { t } = useTranslation();

  const [ imageSrc, setImageSrc ] = useState<string | null>(currentBannerUrl ?? null);
  const [ crop, setCrop ] = useState({ x: 0, y: 0 });
  const [ zoom, setZoom ] = useState(1);
  const [ croppedAreaPixels, setCroppedAreaPixels ] = useState<any>(null);
  const [ imageUrl, setImageUrl ] = useState<string>('');
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[ 0 ];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImageSrc(reader.result as string);
      reader.readAsDataURL(file);
    }
  };

  const handleUrlSubmit = () => {
    if (imageUrl.trim()) {
      setImageSrc(imageUrl.trim());
    }
  };

  const handleCropComplete = (_croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleUpload = async () => {
    if (!imageSrc || !croppedAreaPixels) return;

    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, cropWidth, cropHeight);
    if (croppedImage) {
      const compressedImage = await compressImage(croppedImage);
      const file = new File([ compressedImage ], 'cropped-banner.jpg', { type: 'image/jpeg' });
      onImageUpload(file);
      setImageSrc(null);
      setImageUrl('');
    }
  };

  return (
    <Box sx={ { mt: 4 } }>
      <Typography variant="h4">{ t('pages.admin.loadImageTitle') }</Typography>
      { !imageSrc ? (
        <Box mt={ 1 }>
          <input type="file" accept="image/*" onChange={ handleFileChange } />
          <Box sx={ { mt: 2, display: 'flex', alignItems: 'baseline', gap: 2 } }>
            <TextField
              sx={ { flexGrow: 1 } }
              label="URL"
              value={ imageUrl }
              onChange={ (e) => setImageUrl(e.target.value) }
            />
            <Button variant="contained" color="primary" onClick={ handleUrlSubmit }>
              { t('pages.admin.loadImageButton') }
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={ { position: 'relative', width: '100%', height: 400, mt: 2 } }>
          <Box
            sx={ {
              position: 'absolute',
              top: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              zIndex: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              padding: 1,
            } }>
            <Slider
              value={ zoom }
              min={ 1 }
              max={ 3 }
              step={ 0.1 }
              onChange={ (_, value) => setZoom(value as number) }
              sx={ { width: '85%' } }
            />
            <Button variant="contained" color="primary" onClick={ handleUpload }>
              { t('general.save') }
            </Button>
          </Box>

          <Cropper
            image={ imageSrc }
            crop={ crop }
            zoom={ zoom }
            aspect={ 16 / 9 }
            onCropChange={ setCrop }
            onZoomChange={ setZoom }
            onCropComplete={ handleCropComplete }
          />
        </Box>
      ) }
    </Box>
  );
}

const compressImage = async (image: Blob): Promise<Blob> => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1280,
    useWebWorker: true,
  };

  try {
    const file = new File([ image ], 'temp-image.jpg', { type: image.type });
    return await imageCompression(file, options);
  } catch (error) {
    console.error('Compression error:', error);
    throw error;
  }
};

const getCroppedImg = async (
  imageSrc: string,
  crop: { width: number; height: number; x: number; y: number },
  outputWidth: number,
  outputHeight: number
): Promise<Blob | null> => {
  const image = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Unable to get canvas context');
  }

  canvas.width = outputWidth;
  canvas.height = outputHeight;

  const scaleX = image.width / image.naturalWidth;
  const scaleY = image.height / image.naturalHeight;

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;
  const cropWidth = crop.width * scaleX;
  const cropHeight = crop.height * scaleY;

  ctx.drawImage(
    image,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) resolve(blob);
      else reject(new Error('Canvas is empty'));
    }, 'image/jpeg');
  });
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.crossOrigin = 'anonymous';
    image.src = url;
  });
