import React, { useEffect } from 'react'
import { useConfig } from './Infrastructure/hooks/ConfigProvider'
import AppRouter from './Infrastructure/AppRouter'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { initAppInsights, reactPlugin } from './Infrastructure/appInsight'
import { CartContextProvider } from './shared/cartContext'
import { AuthProvider } from './auth/authContext'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'


const App: React.FC = () => {
  const config = useConfig()

  const stripePromise = loadStripe(config.config?.stripePublicKey ?? '')

  useEffect(() => {
    const initInsights = async () =>
      initAppInsights(config.config?.secrets?.appInsightsSecretKey)

    if (config.config?.secrets.appInsightsSecretKey)
      initInsights()
  }, [])
  
  return (
    <Elements stripe={ stripePromise }>
      <AppInsightsContext.Provider value={ reactPlugin }>
        <CartContextProvider>
          <AuthProvider>
            <AppRouter />
          </AuthProvider>
        </CartContextProvider>
      </AppInsightsContext.Provider>
    </Elements>
  )
}

export default App
