import React, { useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';
import { useThemeMode } from '../utils/theme/themeContext';
import { useCartContext } from '../shared/cartContext';

const drawerWidth = 180;

type TopBarWithLayoutProps = {
  children: React.ReactNode;
  showLoginButton?: boolean;
  showCartButton?: boolean;
  showProfilePicture?: boolean;
};

const TopBarWithLayout: React.FC<TopBarWithLayoutProps> = ({ children, showLoginButton = false, showCartButton = false, showProfilePicture = false }) => {
  const { t } = useTranslation();
  const { theme, isSmScreenOrLower } = useThemeMode();
  const navigate = useNavigate();
  const themeMode = useThemeMode();
  const { user, isAuthenticated, signOut } = useAuth();
  const { getItemsCount } = useCartContext();
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ anchorElUser, setAnchorElUser ] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget)
  const toggleDrawer = (open: boolean) => () => setDrawerOpen(open);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const shouldApplyFullScreen = true

  const menuItems = [
    {
      code: 'cart',
      icon: ShoppingCartOutlinedIcon,
      path: '/cart',
    },
  ];

  const handleLoginClicked = () => {
    navigate(isAuthenticated ? '/home' : '/auth');
  };

  return (
    <>
      <AppBar
        position="static"
        color="transparent"
        sx={ {
          height: '4rem',
          backgroundColor: theme.palette.primary.main,
          paddingX: shouldApplyFullScreen ? '0.3em' : 0,
          ...(shouldApplyFullScreen && { boxShadow: 'none', border: 'none' })
        } }>
        <Container maxWidth={ shouldApplyFullScreen ? false : 'xl' }>
          <Toolbar disableGutters sx={ { justifyContent: 'space-between' } }>
            <Box sx={ { display: 'flex', alignItems: 'center' } }>
              <IconButton
                size="large"
                aria-label="menu"
                onClick={ toggleDrawer(true) }
                sx={ { color: 'white', display: { md: 'none' } } }>
                <MenuIcon />
              </IconButton>
              <Typography
                variant="h3"
                component={ Link }
                to="/"
                sx={ { color: 'white', fontWeight: '1000', textDecoration: 'none' } }>
                { t('general.appName') }
              </Typography>
              <IconButton sx={ { marginLeft: 0.5 } } onClick={ themeMode.toggleTheme }>
                { themeMode.darkMode
                  ? <LightModeOutlinedIcon sx={ { color: '#ededed' } } />
                  : <DarkModeOutlinedIcon sx={ { color: '#ededed' } } />
                }
              </IconButton>
            </Box>

            <Box sx={ { display: 'flex', alignItems: 'center' } }>

              { showCartButton && !isSmScreenOrLower &&
                <IconButton onClick={ () => navigate('/cart') } size="medium">
                  <Badge color="secondary" badgeContent={ getItemsCount() }>
                    <ShoppingCartOutlinedIcon sx={ { color: '#ededed' } } fontSize="medium" />
                  </Badge>
                </IconButton>
              }

              { showLoginButton &&
                <Button onClick={ handleLoginClicked }>
                  { isAuthenticated ? t('components.topBar.goToHome') : t('components.topBar.login') }
                </Button>
              }

              { showProfilePicture && user &&
                <Box sx={ { display: 'flex', alignItems: 'center', marginLeft: 2 } }>
                  <Typography
                    sx={ {
                      color: 'white',
                      fontSize: '1rem',
                      marginRight: '10px',
                      fontWeight: '700',
                    } }>
                    { user.name }
                  </Typography>
                  <IconButton onClick={ handleOpenUserMenu } sx={ { p: 0 } }>
                    <Avatar
                      alt={ user.name }
                      src={ user.profilePicture || '' }
                      sx={ { width: 54, height: 54 } }
                    />
                  </IconButton>
                </Box>
              }
            </Box>
            <UserMenu anchorElUser={ anchorElUser } setAnchorElUser={ setAnchorElUser } />
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="left"
        open={ drawerOpen }
        onClose={ toggleDrawer(false) }
        sx={ {
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            top: '4rem',
          },
        } }>
        <List>
          { menuItems.map((item) => (
            <ListItem key={ item.code } disablePadding>
              <ListItemButton onClick={ () => navigate(item.path) }>
                <item.icon />
                <ListItemText primary={ t('pages.cart.title') } />
              </ListItemButton>
            </ListItem>
          )) }
        </List>
      </Drawer>

      <Box
        sx={ {
          height: '100%',
          paddingLeft: drawerOpen ? `${ drawerWidth }px` : '0px',
          transition: 'padding-left 0.3s ease',
        } }>
        <Container
          maxWidth={ shouldApplyFullScreen ? false : 'xl' }
          sx={ { ...(shouldApplyFullScreen && { padding: '0 !important' }) } }>
          { children }
        </Container>
      </Box>
    </>
  );
};

function UserMenu({ anchorElUser, setAnchorElUser }: { anchorElUser: HTMLElement | null, setAnchorElUser: (val: HTMLElement | null) => void }) {
  const { t, i18n } = useTranslation()
  const { signOut, user } = useAuth()
  const navigate = useNavigate()

  const handleCloseUserMenu = (selectedSetting: 'Logout' | 'Language' | 'Admin') => {
    if (selectedSetting === 'Logout')
      signOut()
    else if (selectedSetting === 'Language')
      i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr')
    else if (selectedSetting === 'Admin')
      navigate('/admin')

    setAnchorElUser(null)
  }

  return (
    <Menu
      keepMounted
      sx={ { mt: '45px' } }
      anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
      transformOrigin={ { vertical: 'top', horizontal: 'right' } }
      anchorEl={ anchorElUser }
      open={ Boolean(anchorElUser) }
      onClose={ handleCloseUserMenu }>
      <MenuItem key={ 'Logout' } onClick={ () => handleCloseUserMenu('Logout') } sx={ { marginBottom: 0 } }>
        <Typography textAlign="center" sx={ { my: 0 } }>{ t('components.topBar.logout') }</Typography>
      </MenuItem>
      <MenuItem key={ 'Language' } onClick={ () => handleCloseUserMenu('Language') } sx={ { marginBottom: 0 } }>
        <Typography textAlign="center" sx={ { my: 0 } }>{ t('components.topBar.language') }</Typography>
      </MenuItem>
      {
        !!user?.isAdmin &&
        <MenuItem key={ 'Admin' } onClick={ () => handleCloseUserMenu('Admin') } sx={ { marginBottom: 0 } }>
          <Typography textAlign="center" sx={ { my: 0 } }>{ t('components.topBar.admin') }</Typography>
        </MenuItem>
      }
    </Menu>
  )
}

export default TopBarWithLayout;
