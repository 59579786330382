import React, { useState } from 'react';
import { Box, Tabs, Tab, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TopBarWithLayout from '../components/TopBarWithLayout';
import AdminTutorialTab from '../components/AdminTutorialTab'
import AdminCategoryTab from '../components/AdminCategoryTab'
import AdminFeatureToggleTab from '../components/AdminFeatureToggleTab'

export default function AdminPage(): JSX.Element {
  const { t } = useTranslation();
  const [ activeTab, setActiveTab ] = useState<number>(0);
  const [ snackbar, setSnackbar ] = useState<SnackbarState>({ open: false, message: '', severity: 'success' });

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSnackbarClose = () => setSnackbar({ ...snackbar, open: false });

  async function handleApiCall<T>(apiCall: Promise<{ data: T | null; status: number; message: string }>) {
    const result = await apiCall;
    if (result.status >= 200 && result.status < 300) {
      setSnackbar({ open: true, message: t('pages.admin.success'), severity: 'success' });
      return result.data;
    } else {
      setSnackbar({ open: true, message: `${ t('pages.admin.error') }: ${ result.message || 'Unknown error' }`, severity: 'error' });
      return null;
    }
  }

  return (
    <TopBarWithLayout>
      <Box p={ 1 }>
        <Box sx={ { marginTop: '1em' } }>
          <Tabs value={ activeTab } onChange={ handleTabChange }>
            <Tab label={ t('pages.admin.tutorialsLabel') } sx={ { color: activeTab !== 0 ? '#555' : 'inherit' } } />
            <Tab label={ t('pages.admin.categoriesLabel') } sx={ { color: activeTab !== 0 ? '#555' : 'inherit' } } />
            <Tab label={ t('pages.admin.featureTogglesLabel') } sx={ { color: activeTab !== 0 ? '#555' : 'inherit' } } />
          </Tabs>

          { activeTab === 0 && <AdminTutorialTab setSnackbar={ setSnackbar } handleApiCall={ handleApiCall } /> }
          { activeTab === 1 && <AdminCategoryTab setSnackbar={ setSnackbar } handleApiCall={ handleApiCall } /> }
          { activeTab === 2 && <AdminFeatureToggleTab setSnackbar={ setSnackbar } handleApiCall={ handleApiCall } /> }

          <Snackbar
            anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
            open={ snackbar.open }
            autoHideDuration={ 6000 }
            onClose={ handleSnackbarClose }>
            <Alert severity={ snackbar.severity } onClose={ handleSnackbarClose }>
              { snackbar.message }
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </TopBarWithLayout>
  );
}
//
// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   Tabs,
//   Tab,
//   TextField,
//   Typography,
//   Snackbar,
//   Alert,
//   MenuItem,
//   Select,
//   InputAdornment, SelectChangeEvent, Container,
// } from '@mui/material';
// import MDEditor from '@uiw/react-md-editor';
// import '@uiw/react-md-editor/markdown-editor.css';
// import '@uiw/react-markdown-preview/markdown.css';
// import { useTranslation } from 'react-i18next';
// import useApi from '../utils/api';
// import ImageCropUploader from '../components/ImageCropUploader'
// import { FullCategory } from '../models/dtos/category'
// import { AdminFullTutorialDto } from '../models/dtos/adminFullTutorialDto'
// import { Guid } from 'guid-typescript'
// import { useThemeMode } from '../utils/theme/themeContext'
// import TopBarWithLayout from '../components/TopBarWithLayout'
//
//
// const defaultTutorial: AdminFullTutorialDto = {
//   id: null,
//   code: '',
//   title: '',
//   description: '',
//   language: 'fr',
//   version: '2024',
//   price: '',
//   tags: '',
//   content: '',
//   categoryId: '',
//   bannerUrl: '',
// };
//
// export default function AdminPage(): JSX.Element {
//   const api = useApi();
//   const { isSmScreenOrLower } = useThemeMode()
//   const { t } = useTranslation();
//
//   const [ activeTab, setActiveTab ] = useState<number>(0);
//   const [ tutorials, setTutorials ] = useState<AdminFullTutorialDto[]>([]);
//   const [ categories, setCategories ] = useState<FullCategory[]>([]);
//   const [ tutorial, setTutorial ] = useState<AdminFullTutorialDto>(defaultTutorial);
//   const [ content, setContent ] = useState<string>('');
//   const [ snackbar, setSnackbar ] = useState<SnackbarState>({ open: false, message: '', severity: 'success' });
//
//   useEffect(() => {
//     fetchTutorials()
//     fetchCategories()
//   }, []);
//
//   const fetchTutorials = async () => {
//     const result = await api.get<AdminFullTutorialDto[]>('/adminProduct/tutorials');
//     setTutorials(result.data || []);
//   }
//
//   const fetchCategories = async () => {
//     const result = await api.get<FullCategory[]>('/adminProduct/categories');
//     setCategories(result.data || []);
//   }
//
//   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     const { name, value } = event.target;
//     setTutorial((prev: AdminFullTutorialDto) => ({ ...prev, [ name ]: value }));
//   };
//
//   const handleSelectChange = (event: SelectChangeEvent) => {
//     const { name, value } = event.target;
//     if (name) {
//       setTutorial((prev: AdminFullTutorialDto) => ({ ...prev, [ name ]: value as string }));
//     }
//   };
//
//   const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
//     setActiveTab(newValue);
//   };
//
//   const handleSelectTutorial = (id: Guid) => {
//     const selected = tutorials.find((t) => t.id === id) || defaultTutorial;
//     setTutorial(selected);
//     setContent(selected.content);
//   };
//
//   const handleSaveTutorial = async () => {
//     if (!tutorial.id) {
//       await handleApiCall(api.post('/adminProduct/tutorial', tutorial))
//     } else {
//       await handleApiCall(api.put('/adminProduct/tutorial', tutorial))
//       if (content !== tutorial.content)
//         await handleApiCall(api.post('/adminProduct/tutorial/content', { tutorialId: tutorial.id, content }))
//     }
//     await fetchTutorials()
//   }
//
//   async function handleOnImageUpload(file: any) {
//     if (!!tutorial.id) {
//       const formData = new FormData();
//       formData.append('file', file);
//       formData.append('tutorialId', tutorial.id.toString());
//
//       await handleApiCall(api.postFormData('/adminProduct/tutorial/banner', formData))
//       await fetchTutorials()
//     }
//   }
//
//   async function handleApiCall<T>(apiCall: Promise<{ data: T | null; status: number; message: string }>) {
//     const result = await apiCall;
//     if (result.status >= 200 && result.status < 300) {
//       setSnackbar({ open: true, message: t('pages.admin.success'), severity: 'success' });
//       return result.data;
//     } else {
//       setSnackbar({ open: true, message: `${ t('pages.admin.error') }: ${ result.message || 'Unknown error' }`, severity: 'error' });
//       return null;
//     }
//   }
//
//   return (
//     <TopBarWithLayout>
//       <Box p={1}>
//         <Box sx={ { marginTop: '1em' } }>
//           <Tabs value={ activeTab } onChange={ handleTabChange }>
//             <Tab label={ t('pages.admin.tutorialsLabel') } sx={ { color: activeTab !== 0 ? '#555' : 'inherit' } } />
//             <Tab label={ t('pages.admin.categoriesLabel') } sx={ { color: activeTab !== 0 ? '#555' : 'inherit' } } />
//             <Tab label={ t('pages.admin.featureTogglesLabel') } sx={ { color: activeTab !== 0 ? '#555' : 'inherit' } } />
//           </Tabs>
//
//           { activeTab === 0 && (
//             <Box sx={ { mt: 4 } }>
//               <Typography variant="h2" sx={ { marginBottom: '1em', fontWeight: 'bold' } }>
//                 { t('pages.admin.manageTutorials') }
//               </Typography>
//
//               <Box display="grid" gridTemplateColumns={ { xs: '1fr', sm: '1fr', md: '1fr 1fr' } } gap={ isSmScreenOrLower ? 1 : 2 }>
//                 <Select
//                   value={ tutorial.id || 'new' }
//                   name="id"
//                   onChange={ (e) => handleSelectTutorial(e.target.value as Guid) }
//                   fullWidth>
//                   <MenuItem value="new">{ t('pages.admin.newTutorial') }</MenuItem>
//                   { tutorials.map((t) => (
//                     <MenuItem key={ t.id?.toString() } value={ t.id?.toString() }>
//                       { t.title }
//                     </MenuItem>
//                   )) }
//                 </Select>
//
//                 <Box display="grid" gridTemplateColumns={ { xs: '1fr 1fr', md: 'repeat(4, 1fr)' } } gap={ isSmScreenOrLower ? 1 : 2 }>
//                   <TextField
//                     label={ t('general.code') }
//                     name="code"
//                     value={ tutorial.code }
//                     onChange={ handleInputChange }
//                     fullWidth />
//                   <Select
//                     value={ tutorial.language }
//                     name="language"
//                     onChange={ handleSelectChange }
//                     fullWidth>
//                     <MenuItem value="fr">FR</MenuItem>
//                     <MenuItem value="en">EN</MenuItem>
//                   </Select>
//
//                   <TextField
//                     label={ t('general.price') }
//                     name="price"
//                     value={ tutorial.price }
//                     slotProps={ { input: { endAdornment: <InputAdornment disableTypography position="end">€</InputAdornment> } } }
//                     onChange={ (e) => {
//                       const value = e.target.value;
//                       if (/^\d*\.?\d*$/.test(value)) handleInputChange(e);
//                     } }
//                     fullWidth />
//                   <Select
//                     value={ tutorial.version }
//                     name="version"
//                     onChange={ handleSelectChange }
//                     fullWidth>
//                     <MenuItem value="2023">2023</MenuItem>
//                     <MenuItem value="2024">2024</MenuItem>
//                     <MenuItem value="2025">2025</MenuItem>
//                   </Select>
//                 </Box>
//               </Box>
//
//               <Box display="grid" gridTemplateColumns={ { xs: '1fr', md: '3fr 1fr' } } gap={ isSmScreenOrLower ? 0 : 2 }>
//                 <TextField
//                   label={ t('general.title') }
//                   name="title"
//                   value={ tutorial.title }
//                   onChange={ handleInputChange }
//                   fullWidth />
//
//                 <Select
//                   value={ tutorial.categoryId }
//                   name="categoryId"
//                   onChange={ handleSelectChange }
//                   fullWidth>
//                   { categories.map((x) => (
//                     <MenuItem key={ x.id.toString() } value={ x.id.toString() }>
//                       { `${ x.code } - ${ x.name }` }
//                     </MenuItem>
//                   )) }
//                 </Select>
//               </Box>
//
//               <Box sx={ { display: 'flex', flexDirection: 'column' } } gap={ isSmScreenOrLower ? 0 : 2 }>
//                 <TextField label={ t('general.tags') } name="tags" value={ tutorial.tags } onChange={ handleInputChange } fullWidth multiline rows={ 2 } />
//                 <TextField label={ t('general.description') } name="description" value={ tutorial.description } onChange={ handleInputChange } fullWidth multiline rows={ 4 } />
//                 { !!tutorial.id &&
//                   <Box>
//                     <Typography variant="h4">{ t('general.content') }</Typography>
//                     <MDEditor style={ { marginTop: '1em' } } height={ 800 } value={ content } onChange={ (value) => setContent(value || '') } />
//                   </Box>
//                 }
//                 { !!tutorial.id &&
//                   <ImageCropUploader
//                     currentBannerUrl={ tutorial.bannerUrl }
//                     cropWidth={ 1920 }
//                     cropHeight={ 1080 }
//                     onImageUpload={ async (file: any) => await handleOnImageUpload(file) }
//                   />
//                 }
//                 <Button variant="contained" color="primary" onClick={ handleSaveTutorial } sx={ { mt: 2, mb: 2 } }>
//                   { tutorial.id ? t('general.update') : t('general.create') }
//                 </Button>
//               </Box>
//             </Box>
//           ) }
//
//           <Snackbar
//             anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
//             open={ snackbar.open }
//             autoHideDuration={ 6000 }
//             onClose={ () => setSnackbar({ ...snackbar, open: false }) }>
//             <Alert severity={ snackbar.severity } onClose={ () => setSnackbar({ ...snackbar, open: false }) }>
//               { snackbar.message }
//             </Alert>
//           </Snackbar>
//         </Box>
//       </Box>
//     </TopBarWithLayout>
//   );
// }